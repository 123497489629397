import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
// import About from '../views/Company/About.vue'
// import Contact from '../views/Company/Contact.vue'
// import TrenchlessConstruction from '../views/Pages/TrenchlessConstruction.vue'
// import CCTVInspections from '../views/Pages/CCTVInspections.vue'
// import PipeBursting from '../views/Pages/PipeBursting.vue'
// import PipeJacking from '../views/Pages/PipeJacking.vue'
// import HDPEButtFusionWelding from '../views/Pages/HDPEButtFusionWelding.vue'
// import VibratingPlowing from '../views/Pages/VibratingPlowing.vue'
// import HorizontalDrilling from '../views/Pages/HorizontalDrilling.vue'
import PageNotFound from '../views/404/PageNotFound.vue'
import store from '../store'

const routes = [




	{
	  path: '/',
	  name: 'home',
	  component: Home
	},
	{
	  path: '/about',
	  name: 'About',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Company/About.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import(/* webpackChunkName: "contact" */ '../views/Company/Contact.vue')
	},







	{
		path: '/trenchless_construction',
		name: 'Trenchless Construction',
		component: () => import(/* webpackChunkName: "trenchless_construction" */ '../views/Pages/TrenchlessConstruction.vue')
	},

	{
		path: '/cctv_inspections',
		name: 'CCTV Inspections',
		component: () => import(/* webpackChunkName: "cctv_inspections" */ '../views/Pages/CCTVInspections.vue')
	},

	{
	path: '/pipe_bursting',
	name: 'Pipe Bursting',
	component: () => import(/* webpackChunkName: "pipe_bursting" */ '../views/Pages/PipeBursting.vue')
	},

	{
		path: '/pipe_jacking',
		name: 'Pipe Jacking',
		component: () => import(/* webpackChunkName: "pipe_jacking" */ '../views/Pages/PipeJacking.vue')
	},

	{
	path: '/hdpe_butt_fusion_welding',
	name: 'HDPE Butt Fusion Welding',
	component: () => import(/* webpackChunkName: "hdpe_butt_fusion_welding" */ '../views/Pages/HDPEButtFusionWelding.vue')
	},

	{
		path: '/vibrating_plowing',
		name: 'Vibrating Plowing',
		component: () => import(/* webpackChunkName: "vibrating_plowing" */ '../views/Pages/VibratingPlowing.vue')
	},

	{
	path: '/horizontal_drilling',
	name: 'Horizontal Drilling',
	component: () => import(/* webpackChunkName: "horizontal_drilling" */ '../views/Pages/HorizontalDrilling.vue')
	},

	{
		path: '/blog_articles',
		name: 'Blog Articles',
		component: () => import(/* webpackChunkName: "blog_articles" */ '../views/Blogs/Blogs.vue'),
		beforeRouteEnter (to, from, next) {
			store.dispatch('Blogs/setSelectedBlog', '');
			next();
		}
	},


	{
		path: '/blog_article',
		name: 'Blog Article',
		component: () => import(/* webpackChunkName: "blog_articles" */ '../views/Blogs/BlogView.vue')
	},




	{
		path: '/:pathMatch(.*)',
		component: PageNotFound,
		meta: {
			title: 'Page Not Found...'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		if(savedPosition)
		{
			return savedPosition
		}
		else
		{
			return { 
				top: 0,
				behavior: 'smooth' 
			}
		}
	},
})

export default router
