import { createStore } from 'vuex'

import App from './app'

import Blogs from '../views/Blogs/Blogs.js'

export default createStore({
  modules: {
    App,
    Blogs
  }
})
