<template>
    <div>
        404 - Page not found...
    </div>
</template>




<script>
export default {

    data() {
        return {

        }
    },



    
}
</script>




<style>
    
</style>