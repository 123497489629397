// initial state
const state = () => ({
    mobile: false
})





// getters
const getters = {
    mobile: (state) => state.mobile
}





// actions
const actions = {
    mobile({commit, state}, toggle) {
        commit('mobile', toggle);
    }
}





// mutations
const mutations = {
    mobile(state, toggle) {
        state.mobile = toggle;
    },
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

//remember to reg in index.js 