<template>
    <div class="mobile-navigation-wrap" :class="{ 'add-height' : scrollTop < 5 }">

        <div class="menu-toggle-wrap" :class="{ 'move-up' : scrollTop > 5 }" @click="menuOpen = !menuOpen">
            <div class="menu-toggle" :class="{ 'menu-open' : menuOpen }"></div>
        </div>
        
        <div v-if="menuOpen" class="mobile-menu-wrap" :class="{ 'move-down' : scrollTop < 5 }">
            <router-link class="mobile-menu-link" @click="menuOpen = false" to="/">Home</router-link>
            <router-link class="mobile-menu-link" @click="menuOpen = false" to="/trenchless_construction" >Trenchless Construction</router-link>

            <router-link class="mobile-menu-link menu-list-link" @click="menuOpen = false" to="/horizontal_drilling">- Horizontal Drilling</router-link>
            <router-link class="mobile-menu-link menu-list-link" @click="menuOpen = false" to="/pipe_jacking">- Pipe Jacking</router-link>
            <router-link class="mobile-menu-link menu-list-link" @click="menuOpen = false" to="/pipe_bursting">- Pipe Bursting</router-link>
            <router-link class="mobile-menu-link menu-list-link" @click="menuOpen = false" to="/cctv_inspections">- CCTV Inspections</router-link>
            <router-link class="mobile-menu-link menu-list-link" @click="menuOpen = false" to="/vibrating_plowing">- Vibrating Plowing</router-link>
            <router-link class="mobile-menu-link menu-list-link" @click="menuOpen = false" to="/hdpe_butt_fusion_welding">- HPDE Butt Fusion Welding</router-link>

            <router-link class="mobile-menu-link" @click="menuOpen = false" to="/blog_articles">Blog Articles</router-link>
            <router-link class="mobile-menu-link" @click="menuOpen = false" to="/about">About</router-link>
            <router-link class="mobile-menu-link" @click="menuOpen = false" to="/contact">Contact</router-link>
        </div>


    </div>
</template>



<script>
import { mapGetters } from 'vuex';

export default {

    props: ['scrollTop'],

    
    data() {
        return {
            menuOpen: false,
        }
    },



    computed: {
        ...mapGetters({
            mobile: ['App/mobile']
        })
    },



    mounted() {
        
    },



    methods: {
        
    },


}
</script>



<style>

.mobile-navigation-wrap {

}

.mobile-navigation-wrap.add-height {

}









.mobile-menu-wrap {
    position: fixed;
    top: 65px;
    right: 0;
    width: 80%;
    max-width: 250px;
    background: rgba(26, 49, 90, 0.95);
    transition: top 250ms ease;
    font-weight: 700;
}

.mobile-menu-wrap.move-down {
    top: 85px;
    transition: top 250ms ease;
}





.mobile-menu-link {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid rgba(240,240,240,0.3);
    padding-left: 15px;
    width: 100%;
}

.mobile-menu-link:last-child {
    border-bottom: none;
}


.mobile-menu-link.menu-list-link {
    padding-left: 25px;
}


.mobile-menu-wrap a {
    color: white;
    text-decoration: none;
}









.menu-toggle-wrap {
    position: absolute;
    top: 48px;
    right: 40px;
    z-index: 1500;
    transition: top 250ms ease-in-out;
}

.menu-toggle-wrap.move-up {
    top: 39px;
    transition: top 250ms ease-in-out;
}



.menu-toggle::before {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 15px;
    height: 2px;
    content: '';
    border-radius: 5px;
    background: var(--AardvarkBlueLight);
    transition: transform 150ms ease, width 150ms ease;
}

.menu-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 25px;
    height: 2px;
    content: '';
    border-radius: 5px;
    background: var(--AardvarkBlueLight);
}

.menu-toggle::after {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 15px;
    height: 2px;
    content: '';
    border-radius: 5px;
    background: var(--AardvarkBlueLight);
    transition: width 150ms ease;

}





.menu-toggle.menu-open::before {
    transform: rotate(90deg);
    width: 25px;
    top: 0;
    transition: transform 150ms ease, width 150ms ease, top 150ms ease;
}

.menu-toggle.menu-open {
    transform: rotate(45deg);
    transition: transform 150ms ease;
}

.menu-toggle.menu-open::after {
    width: 0;
    transition: width 150ms ease;
}

    
</style>