<template>
    <div class="header-wrap" :class="{ 'add-height' : scrollTop < 5 }">

        <div v-if="screenWidth >= 850">

            <div class="trapezoid-logo-wrapper"></div>

            <div class="top-header-logo-wrap" @click="$router.push('/')" :class="{ 'reduce-size' : scrollTop > 5 }">
                <img src="@/assets/aardvark_trenchless_construction_logo_transparent_461x114.png" :class="{ 'reduce-size' : scrollTop > 5 }" alt="aardvark_construction_logo">
            </div>

            <div class="mini-menu-right-wrap">
                <div class="mini-menu-right-item">
                    <router-link to="/about">About</router-link>
                </div>
                <div class="mini-menu-right-item">
                    <router-link to="/contact">Contact</router-link>
                </div>
            </div>

            <TopNavigation :scrollTop="scrollTop" />

        </div>
        <div v-else>
            <div class="trapezoid-logo-wrapper mobile"></div>

            <div class="top-header-logo-wrap mobile" @click="$router.push('/')" :class="{ 'reduce-size' : scrollTop > 5 }">
                <img src="@/assets/aardvark_trenchless_construction_logo_transparent_461x114.png" :class="{ 'reduce-size' : scrollTop > 5 }" alt="aardvark_construction_logo">
            </div>

            <MobileNav :scrollTop="scrollTop" />

        </div>
        
    </div>
</template>



<script>

import TopNavigation from './TopNavigation.vue'
import MobileNav from './MobileNav.vue';

export default {


    props: ['scrollTop'],



    components: {
        TopNavigation,
        MobileNav
    },
    
    data() {
        return {
            screenWidth: window.innerWidth
        }
    },



    watch: {
        screenWidth: {
            handler: function() {
                console.log('Screen width: ', this.screenWidth + 'px');
            },
            deep: true
        }
    },



    mounted() {
        window.addEventListener('resize', () => {
            this.screenWidth = window.innerWidth;
        })
    }

}
</script>



<style>

.header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 70px;
    background: rgb(255, 255, 255);
    box-shadow: 0 -2px 8px 0 rgba(0,0,0,0.4);
    transition: height 200ms ease-out;
    z-index: 999;
    line-height: 1;
}

.header-wrap.add-height {
    height: 90px;
    transition: height 200ms ease-out;
}






.trapezoid-logo-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: #7fce24;
    clip-path: polygon(0 0, 0 30%, 2% 30%, 4% 10%, 20% 10%, 22% 30%, 80% 30%, 82% 10%, 100% 10%, 100% 0);
    z-index: 999;
    border-bottom: 1px solid rgba(0,0,0,0.8);
}

.trapezoid-logo-wrapper.mobile {
    clip-path: polygon(0 0, 0 30%, 5% 30%, 10% 10%, 75% 10%, 80% 10%, 100% 10%, 100% 0);
    max-width: 100vw;
}








.top-header-logo-wrap {
    position: absolute;
    top: 8px;
    left: 0.4%;
    height: 90px;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
    cursor: pointer;
    transition: top 250ms ease-out;
}

.top-header-logo-wrap.mobile {
    width: 70%;
    left: 7%;
    top: 4px;
    justify-content: flex-start;
    transition: top 250ms ease-out;
}



.top-header-logo-wrap.reduce-size {
    top: 3px;
    transition: top 250ms ease-in-out;
}

.top-header-logo-wrap.reduce-size.mobile {
    top: -5px;
    transition: top 250ms ease-in-out;
}



.top-header-logo-wrap img {
    width: 82%;
    max-width: 275px;
    transition: width 250ms ease-out;
}

.top-header-logo-wrap.mobile img {
    width: 55%;
    max-width: 180px;
    transition: width 250ms ease-out;
}



.top-header-logo-wrap img.reduce-size {
    width: 75%;
    transition: width 250ms ease-in-out;
}

.top-header-logo-wrap.mobile img.reduce-size {
    width: 45%;
    max-width: 180px;
    transition: width 250ms ease-in-out;
}










.mini-menu-right-wrap {
    position: fixed;
    top: 20px;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 998;
}

.mini-menu-right-item {
    margin-right: 15px;
    font-weight: 700;
    cursor: pointer;
    
}

.mini-menu-right-item a {
    text-decoration: none;
    color: var(--AardvarkBlue);
    transition: color 250ms ease-out;
}

.mini-menu-right-item a:hover {
    color: var(--AardvarkGreen);
    transition: color 250ms ease-out;
    
}




@media only screen and (max-width: 850px) {

.header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 65px;
    background: rgb(255, 255, 255);
    box-shadow: 0 -2px 8px 0 rgba(0,0,0,0.4);
    transition: height 200ms ease-out;
    z-index: 999;
    line-height: 1;
}

.header-wrap.add-height {
    height: 85px;
    transition: height 200ms ease-out;
}
    
}
    
</style>