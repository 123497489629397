<template>
    <div class="top-navigation-wrap" :class="{ 'add-height' : scrollTop < 5 }">
        <div class="top-menu-flex" :class="{ 'move-line' : scrollTop }">
            <router-link class="menu-link" to="/">Home</router-link>
            <router-link to="/trenchless_construction" class="menu-link menu-item-with-drop-down" @mouseover="dropDownActive = true" @mouseleave="dropDownActive = false">
                Trenchless Construction
                <div v-if="dropDownActive" class="trenchless-drop-down-wrap">
                    <router-link @click="dropDownActive = false" class="drop-down-link" to="/horizontal_drilling">Horizontal Drilling</router-link>
                    <router-link @click="dropDownActive = false" class="drop-down-link" to="/pipe_jacking">Pipe Jacking</router-link>
                    <router-link @click="dropDownActive = false" class="drop-down-link" to="/pipe_bursting">Pipe Bursting</router-link>
                    <router-link @click="dropDownActive = false" class="drop-down-link" to="/cctv_inspections">CCTV Inspections</router-link>
                    <router-link @click="dropDownActive = false" class="drop-down-link" to="/vibrating_plowing">Vibrating Plowing</router-link>
                    <router-link @click="dropDownActive = false" class="drop-down-link" to="/hdpe_butt_fusion_welding">HPDE Butt Fusion Welding</router-link>
                </div>
            </router-link>
            <router-link class="menu-link" to="/horizontal_drilling">Horizontal Drilling</router-link>
            <router-link class="menu-link" to="/pipe_jacking">Pipe Jacking</router-link>
            <router-link class="menu-link" to="/blog_articles">Blog Articles</router-link>
        </div>
    </div>
</template>



<script>
import { mapGetters } from 'vuex';

export default {

    props: ['scrollTop'],

    
    data() {
        return {
            dropDownActive: false,
        }
    },



    computed: {
        ...mapGetters({
            mobile: ['App/mobile']
        })
    },



    mounted() {
        
    },



    methods: {
        
    },


}
</script>



<style>

.top-navigation-wrap {
    position: absolute;
    top: 36px;
    left: 22%;
    width: 58%;
    height: 34px;
    transition: height 200ms ease-out;
}


.top-navigation-wrap.add-height {
    height: 54px;
    transition: height 200ms ease-out;
}





.top-menu-flex {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    padding: 0 0;
}



.top-menu-flex a {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 0;
    padding: 10px 15px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    position: relative;
    color: var(--AardvarkBlue);
    text-decoration: none;
}


@keyframes grow-from-center {
    0% {
        transform: scale(0, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

.top-menu-flex a.menu-link:hover::after {
    
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 2px;
    /* box-shadow: 0 1px 6px 0 rgb(142, 240, 30); */
    background: var(--AardvarkGreen);
    width: calc(100% - 30px);
    animation: grow-from-center 250ms ease-out;
    transform-origin: center;

    z-index: 1;
    /* background: linear-gradient(to top, rgba(115, 255, 150, 0), rgba(115, 255, 150, 0.4)); */
}


.top-menu-flex.move-line a.menu-link:hover::after {
    bottom: 0px;
}

.top-menu-flex a.menu-link:first-child {
    /* padding-left: 0; */
}

.top-menu-flex a.menu-link:last-child {
    border-right: none;
}



@media only screen and (max-width: 1400px) {
    .top-menu-flex a.menu-link{
        font-size: 14px;
        text-align: center;
    }

    .top-menu-flex a.menu-link:hover::after {
        bottom: 0px;
    }
}




@keyframes grow-from-top {
    0% {
        transform: scale(1, 0.7);
    }
    100% {
        transform: scale(1, 1);
    }
}


@keyframes fade-in-with-pause {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



.menu-item-with-drop-down {
    position: relative;
}


.trenchless-drop-down-wrap {
    position: absolute;
    top: 100%;
    left: 15px;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    background: var(--AardvarkBlue);
    animation: fade-in 400ms ease-out, grow-from-top 300ms ease-out;
    transform-origin: top;
}


.trenchless-drop-down-wrap a.drop-down-link {
    padding: 12px 15px;
    z-index: 99;
    cursor: pointer;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    transition: background 250ms ease-out, border-bottom 250ms ease-out;
    text-align: left;
    background: var(--AardvarkBlue);
    color: white;
    font-weight: 500;
}


.trenchless-drop-down-wrap a.drop-down-link:last-child {
    border-bottom-color: transparent;
    padding-bottom: 11px;
}


.trenchless-drop-down-wrap a.drop-down-link:hover {
    background: rgba(255,255,255,0.1);
    border-bottom: 1px solid var(--AardvarkGreen);
    transition: background 250ms ease-out, border-bottom 250ms ease-out;
}



@media screen and (max-width: 800px) 
{

.top-navigation-wrap {
    
}

}

    
</style>