<template>

	<Header :scrollTop="scrollTop" />
	<router-view />
	<Footer />
</template>


<script>

import Header from './views/Header/Header.vue'
import Footer from './views/Footer/Footer.vue'



export default {

	components: {
		Header,
		Footer
	},
	
	data() {
		return {
			scrollTop: 0,
		}
	},


	mounted() {
        this.setMobile();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.setMobile());

        
    },



    methods: {
        handleScroll() {
            this.scrollTop = window.scrollY;
        },


        setMobile: function() {
            if(window.innerWidth <= 800)
                this.$store.dispatch('App/mobile', true);
            else
                this.$store.dispatch('App/mobile', false);
        },
    },

}

</script>



<style>

.grecaptcha-badge {
  z-index: 2000;
}


/* Colors */

:root {
	--AardvarkGreen: rgb(127, 206, 36);
	--AardvarkGreenText: rgb(118, 189, 38);
	--AardvarkGreenLight: rgb(198, 255, 133);
	--AardvarkGreenDark: rgb(91, 160, 13);
	--AardvarkBlue: rgb(25,48,87);
	--AardvarkBlueDark: rgb(14, 25, 44);
	--AardvarkBlueLighter: rgb(31, 60, 110);
    --AardvarkBlueLight: rgb(44, 72, 133);
}


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Helvetica', 'Arial', sans-serif;
	scroll-behavior: smooth;
}

body {
	/* margin-bottom: 400px; */
	
	padding-top: 140px;
	/* padding-bottom: 450px; */
	position: relative;
	min-height: 100vh;
	line-height: 1.65;
}

body ol,
body ul {
	padding-left: 15px;
    margin: 10px 0;
}


*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-track {
	background: rgba(109, 109, 109, 0.705);
	/* background: #7fce24; */
}

*::-webkit-scrollbar-thumb {
	background: rgb(25,48,87);
}

*::-webkit-scrollbar-thumb:hover {
	background: rgb(62, 119, 216);
	background: #7fce24;
	
}



.bold {
	font-weight: 700;
}


.align-center {
	text-align: center;
}





.page-content {
	padding: 0 10%;
}


.page-header {
	font-size: 32px;
    text-transform: uppercase;
    color: var(--AardvarkBlue);
    text-align: center;
    width: max-content;
    position: relative;

	margin-bottom: 40px;
}


.page-header::after {
    position: absolute;
    left: 0;
    content: '';
    display: block;
    width: calc(100% + 40px);
    height: 2px;
    background: var(--AardvarkGreen);
    margin: 0 auto;
}




.paragraph-heading {
	color: var(--AardvarkBlue);
    margin-bottom: 10px;
    text-transform: uppercase;
}

.paragraph {
	margin-bottom: 20px;
}






.centered-content {
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}





.image-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px 0;
}

.image-wrapper img {
    margin: 0 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 700px;
    height: 100%;
    object-fit: contain;
}





.two-column-text-image-grid {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    align-items: center;
    column-gap: 50px;
}


.two-column-text-image-grid .image-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px 0;
}


.two-column-text-image-grid .image-side img {
    max-width: 100%;
    margin-bottom: 20px;
}




@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');




@media screen and (max-width: 850px) 
{

body {
    padding-bottom: 400px;
    outline: none;
}



.page-header {
	font-size: 32px;
    text-transform: uppercase;
    color: var(--AardvarkBlue);
    text-align: center;
    width: 90%;
    position: relative;

	margin-bottom: 40px;
}


.page-header::after {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: var(--AardvarkGreen);
}


.page-content iframe {
    max-width: 100%;
}


.two-column-text-image-grid {
    grid-template-columns: 1fr;
}

}
</style>
