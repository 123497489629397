<template>
    <div class="footer-wrap">
        
        <div class="footer-content-wrap">
            
            <div class="footer-content left">
                <img src="@/assets/aardvark_trenchless_construction_logo_white_transparent_461x114.png" alt="aardvark-construction-logo-white-no_background">
            </div>

            <div class="footer-content middle">
                <h3>Trenchless Technology -</h3>
                <h3>The Future of Civil Construction</h3>
            </div>

            <div class="footer-content right">
                <h4><router-link to="/contact">CONTACT US</router-link></h4>
                <div class="footer-contact-us-flex-wrap">
                    <font-awesome-icon class="footer-contact-us-icon" :icon="['fa', 'location-dot']" size="lg" />
                    <p><a href="https://goo.gl/maps/bzrU9MH154p7ziJbA" target="_blank">South Africa</a></p>
                </div>
                <div class="footer-contact-us-flex-wrap">
                    <font-awesome-icon class="footer-contact-us-icon" :icon="['fa', 'mobile-screen']" size="lg" />
                    <p><a href="tel:+27834558321">+27 (0) 83 455 8321</a></p>
                </div>
                <div class="footer-contact-us-flex-wrap">
                    <font-awesome-icon class="footer-contact-us-icon" :icon="['fa', 'envelope']" size="lg" />
                    <p><a href="mailto:carl@aardvarkconstruction.co.za">carl@aardvarkconstruction.co.za</a></p>
                </div>
                <div class="footer-contact-us-flex-wrap">
                    <font-awesome-icon class="footer-contact-us-icon" :icon="['fa', 'earth-africa']" size="lg" />
                    <p><a href="https://www.aardvarkconstruction.co.za">www.aardvarkconstruction.co.za</a></p>
                </div>
            </div>
        </div>
        <div class="footer-trapezoid">
            <p class="copyright">Copyright &COPY; {{ currentYear }} - Aardvark Construction & Drilling </p>
        </div>
        
    </div>
</template>




<script>
export default {

    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    },


    
}
</script>




<style>


.footer-wrap {
    width: 100%;
    height: 300px;
    /* background: rgba(22, 106, 145, 0.99); */
    z-index: 999;
}






/* Footer Content Wrap */

.footer-content-wrap {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 220px;
    background: var(--AardvarkBlue);

    display: flex;
    align-items: center;
    padding-left: 20px;
}


.footer-content {
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
}





/* Footer Left */

.footer-content.left {
    width: 30%;
}

.footer-content.left img {
    width: 80%;
    max-width: 350px;
}






/* Footer Middle Content */

.footer-content.middle {
    width: 40%;
    padding-right: 5%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.footer-content.middle h3 {
    font-size: 1.4em;
    font-weight: 600;
    
    text-transform: uppercase;
    color: var(--AardvarkGreen);
}









/* Footer Right */

.footer-content.right {
    width: 30%;
    padding-left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer-content.right h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--AardvarkGreen);
}


.footer-contact-us-flex-wrap {
    display: flex;
    align-items: center;
}

.footer-contact-us-flex-wrap p {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
}


.footer-content.right a,
.footer-contact-us-flex-wrap a {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    color: white;
    text-decoration: none;
}

.footer-content.right h4 a {
    font-size: 19px;
    font-weight: 700;
    color: var(--AardvarkGreen);
}

.footer-contact-us-icon {
    color: white;
    margin-right: 12px;
    margin-bottom: 10px;
}







/* Footer Bottom */

.footer-trapezoid {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: #7fce24;
    clip-path: polygon(0 100%, 0 90%, 15% 90%, 17% 70%, 83% 70%, 85% 90%, 100% 90%, 100% 100%);
    z-index: 999;
}


.footer-trapezoid .copyright {
    position: absolute;
    bottom: 7px;  
    right: 18%;
    font-size: 12px;
    display: flex;
    align-items: center;
    z-index: 1000;
}


@media screen and (max-width: 850px) 
{

.footer-content-wrap {
    flex-direction: column;
    justify-content: center;
    height: 600px;
    padding: 0;
}

.footer-content.left,
.footer-content.middle,
.footer-content.right {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 10px;
}

.footer-content.right {
    padding: 0;
}

.footer-content.left img {
    
    /* width: 100%; */
}

.footer-trapezoid .copyright{
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 0.65em;
    bottom: 12px;
}

}
    
</style>