<template>
    <div class="slider-wrap">
        
        <div class="slide-loop" v-for="(slide, index) in slides" :key="slide.id" :class="{ 'show' : index == currentSlideIndex }"> 
        <!-- <div class="slide-vignette"></div>        -->
            <img :src="slide.img" :alt="slide.imgAlt">
            <div class="slide-info-wrap" @mouseenter="mobile ? pauseAutoPlay() : autoPlayOn = false" @mouseleave="mobile ? null : autoPlayOn = true"><!-- @mouseenter="autoPlayOn = false" @mouseleave="autoPlayOn = true" -->
                <h3>{{ slide.title }}</h3>
                <p>{{ slide.body }}</p>
                <router-link :to="slide.link">Read more...</router-link>
            </div>
        </div>
        <div class="slider-actions-wrap">
            <div class="slider-buttons-wrap" @mouseenter="mobile ? pauseAutoPlay() : autoPlayOn = false" @mouseleave="mobile ? null : autoPlayOn = true"><!-- @mouseenter="autoPlayOn = false" @mouseleave="autoPlayOn = true" -->
                <font-awesome-icon @click="previousSlide()" class="navigate-slider-icon left" :icon="['fa', 'circle-chevron-left']" size="lg" />
                <font-awesome-icon @click="nextSlide()" class="navigate-slider-icon right" :icon="['fa', 'circle-chevron-right']" size="lg" />
            </div>
            <div class="contact-info-wrap">
                <a class="slider-contact-link" href="tel:+27834558321"><font-awesome-icon class="slider-contact-icon" :icon="['fa', 'mobile-screen']" size="lg" /> +27 (0) 83 455 8321</a>
                <a class="slider-contact-link" href="mailto:carl@aardvarkconstruction.co.za"><font-awesome-icon class="slider-contact-icon" :icon="['fa', 'envelope']" size="lg" /> carl@aardvarkconstruction.co.za</a>
            </div>
        </div>

    </div>
</template>




<script>
import { mapGetters } from 'vuex'

export default {

    data() {
        return {
            currentSlideIndex: 0,

            slideShowInterval: null,
            slideTime: 5000,
            autoPlayOn: true,


            // create 3 random picture slides
            slides: [
                {
                    id: 0,
                    title: 'Trenhcless Technology',
                    body: 'The future of Civil Construction...',
                    link: '/trenchless_construction',
                    img: require('@/assets/Trenchless-Technology-The-future-of-Civil-Construction.jpg'),
                    imgAlt: 'aardvark_construction_trenchless-technology-the-future-of-civil-construction',
                },
                
                {
                    id: 1,
                    title: 'Pipe Cracking',
                    body: 'The replacement of existing pipelines...',
                    link: '/pipe_bursting',
                    img: require('@/assets/pipe_bursting_sliplining_slide.jpg'),
                    imgAlt: 'aardvark_construction_pipe_bursting_sliplining_slide',
                },
                {
                    id: 2,
                    title: 'Horizontal Directional Drilling',
                    body: 'Installation of HDPE sleeve pipes...',
                    link: '/horizontal_drilling',
                    img: require('@/assets/aardvark_construction_horizontal_directional_drilling_hdpe_sleeve_pipes.jpg'),
                    imgAlt: 'aardvark_construction_horizontal_directional_drilling_hdpe_sleeve_pipes',
                },
                {
                    id: 3,
                    title: 'Pipe Jacking',
                    body: 'Installation of concrete pipes...',
                    link: '/pipe_jacking',
                    img: require('@/assets/aardvark_construction_pipe_jacking_slide.jpg'),
                    imgAlt: 'aardvark_construction_pipe_jacking',
                },
                
            ]
        }
    },





    computed: {
        ...mapGetters({
            mobile: ['App/mobile']
        })
    },





    watch: {
        autoPlayOn: function() {
            // console.log('Auto play on? ', this.autoPlayOn);
            if(this.autoPlayOn)
                this.startSlideShow();
            else
                this.stopSlideShow();
        }
    },



    mounted() {
        this.startSlideShow();
    },



    methods: {


        pauseAutoPlay: function() {
            this.autoPlayOn = false;
            setTimeout(() => {
                this.autoPlayOn = true;
            }, 5000);
        },


        startSlideShow: function() {0
            this.slideShowInterval = setInterval(() => {
                this.nextSlide();  
            }, this.slideTime);
        },


        stopSlideShow: function() {
            clearInterval(this.slideShowInterval);
        },


        previousSlide: function() {
            if(this.currentSlideIndex > 0)
                this.currentSlideIndex--;
            else
                this.currentSlideIndex = this.slides.length - 1;
        },


        nextSlide: function() {
            if(this.currentSlideIndex < this.slides.length - 1)
                this.currentSlideIndex++;
            else
                this.currentSlideIndex = 0;
        },

    },
    
}
</script>




<style>

.slider-wrap {
    width: 100%;
    height: 650px;
    max-height: calc(100vh - 90px);
    position: relative;
    background: rgb(20,20,20);
    margin-top: -50px;
}



@keyframes fade-in {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}


@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}


.slide-loop {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 99;
    opacity: 0;
    animation: fade-out 1200ms ease-out;
}

.slide-loop.show {
    z-index: 100;
    opacity: 1;
    animation: fade-in 1200ms ease-out;
}


.slide-image-container {
    position: absolute;
    height: 100%;
    width: 100%;
}


.slide-loop img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 90;
}

.slide-loop img.show {
    z-index: 100;
}



.slide-vignette {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: linear-gradient(rgba(0,0,0,0)10%, rgba(0,0,0,0.5)); */
    z-index: 95;
}








.slide-info-wrap {
    position: absolute;
    top: 100px;
    left: 12%;
    width: 60%;
    max-width: 800px;
    padding: 20px 20px;
    padding-top: 10px;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.7);
    background: rgba(0,0,0,0.7);
}


.slide-info-wrap h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--AardvarkGreen);
}

.slide-info-wrap p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}


.slide-info-wrap a {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: var(--AardvarkBlueLight);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 17px;
    font-weight: 700;
    transition: color 250ms ease-out;
    transition: transform 100ms ease, color 250ms ease-out, background 100ms ease;
}

.slide-info-wrap a:hover {
    background: var(--AardvarkBlueLighter);
    transition: transform 100ms ease, background 100ms ease-out;
    transform: translate(-1px, 1px);
}






.slider-actions-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: rgba(0,0,0,0.7);
    z-index: 100;
    display: flex;
    align-items: center;
    padding-right: 50px;
}

.slider-buttons-wrap {
    height: 100%;
    width: 281px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 92px;
    padding-right: 154px;
    background: linear-gradient(to right, rgba(0,0,0,0.8) 80%, rgba(0,0,0,0.0));
}






.navigate-slider-icon {
    font-size: 36px;
    color: var(--AardvarkBlueLight);
    margin: 0 5px;
    cursor: pointer;
    transition: color 100ms ease;
}

.navigate-slider-icon:hover {
    color: var(--AardvarkBlueLighter);
    transition: color 100ms ease;
}



.contact-info-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
}


.slider-contact-icon {
    color: var(--AardvarkGreen);
}


.slider-contact-link {
    color: white;
    font-size: 14px;
    font-weight: 300;
    margin-right: 35px;
    text-decoration: none;
    transition: color 250ms ease-out;
}

.slider-contact-link:last-child {
    margin-right: 10px;
}











@media screen and (max-width: 800px) 
{


.slider-wrap {
    margin-top: -55px;
}


.slide-info-wrap {
    position: absolute;
    top: 20px;
    left: 5%;
    width: 90%;
    padding-bottom: 80px;
    text-align: center;
}


.slide-info-wrap h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--AardvarkGreen);
}

.slide-info-wrap p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}


.slide-info-wrap a {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 150px;
    transition: none;
    transition: none;
}

.slide-info-wrap a:hover {
    background: var(--AardvarkBlueLight);
    transition: none;
    transform: none;
}











.slider-buttons-wrap {
    width: 100%;
    padding: 0 20px;
    position: absolute;
    left: 0;
    top: -70px;
    justify-content: space-between;
    background: transparent;
    color: rgba(255,255,255,0.7);
}

.navigate-slider-icon {
    color: rgba(255,255,255,0.55);
    /* box-shadow: -2px 2px 8px 0 rgba(0,0,0,0.25); */
}

.navigate-slider-icon:hover {
    color: rgba(255,255,255,0.55);
}

.slider-buttons-wrap svg {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}


.slider-actions-wrap {
    width: 100%;
}


.contact-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    width: 100%;
    position: absolute;
}

.slider-contact-link {
    margin: 0;
    margin-bottom: 5px;
}



}
    
</style>