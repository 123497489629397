import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
    faCircleChevronLeft,
    faCircleChevronRight,
    faEarthAfrica,
    faEnvelope,
    faLocationDot, 
    faMobileScreen 
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faLocationDot,
    faMobileScreen,
    faEnvelope,
    faEarthAfrica,
    faCircleChevronRight,
    faCircleChevronLeft
)

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const recaptchaSiteKey = window.location.href.includes('localhost') ? '6Lf1a0UoAAAAAPbhPRWgW0lUScKLpy_gikaKY2_4' : window.location.href.includes('bravado.co.za') ? '6LckD1waAAAAALUtuJYSAR8c05SijZEo9MUIo5ml' : '6LcvHMMnAAAAAOoL9ni9nitBNhobaFd1gRrNlHvQ';

createApp(App)
.use(store)
.use(router)
.use(VueReCaptcha, { siteKey: recaptchaSiteKey })
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
