<template>
    <div class="home-wrap">

        <Slider class="home-page-slider-wrap" />

        <div class="content page-content">
        
            <div class="intro-paragraph-wrap home-content-wrap">
                <h3 class="low-top-margin centered">Aardvark Trenchless Construction</h3>
                <p class="intro-paragraph align-center">Aardvark Construction provides trenchless construction solutions for the civil construction industry via various advanced technologies aimed at reducing cost while improving efficiency and turn-around time of projects.</p> 
            </div>

            <div class="about-us-wrap home-content-wrap">
                <h3 class="centered">About Us</h3>
                <p class="align-center">Aardvark Construction executes trenchless construction projects across South Africa and neighboring countries. We have more than 25 years' experience in Civil Construction and 15 years' in Trenchless Construction.</p>
                <p class="align-center">We have offices in Pretoria, Bloemfontein and Cape Town, and Aardvark can deploy teams nationally.</p>
            </div>


            <div class="clients-logos-wrap align-center home-content-wrap">
                <h3 class="centered">Our Clients</h3>
                <p class="">Aardvark has completed projects for several civil contractors, municipalities, mines, telecoms, and private companies. They are, amongst others: </p>
                <div class="client-logo-images-wrap">
                    <img src="@/assets/aeci_logo.png" alt="aeci-logo">
                    <img src="@/assets/bloem_water_logo.png" alt="bloem-water-logo">
                    <img src="@/assets/cellc_logo.png" alt="cell-c-logo">
                    <img src="@/assets/city_of_cape_town_south_africa_logo.png" alt="city-of-cape-town-south-africa-logo">
                    <img src="@/assets/dark_fibre_africa_dfa_logo.png" alt="dark-fibre-africa-dfa-logo">
                    <img src="@/assets/debswana_logo.png" alt="debswana-logo">
                    <img src="@/assets/eskom_logo.png" alt="eskom-logo">
                    <img src="@/assets/south_african_water_and_sanitation_department_logo.png" alt="south-african-water-and-sanitation-department-logo">
                    <img src="@/assets/glencore_logo.png" alt="glencore-logo">
                    <img src="@/assets/joburg_water_logo.png" alt="joburg-water-logo">
                    <img src="@/assets/mtn_logo.png" alt="mtn-logo">
                    <img src="@/assets/sanral_logo.png" alt="sanral-logo">
                    <img src="@/assets/sedibeng_water_logo.png" alt="sedibeng-_water-logo">
                    <img src="@/assets/mopani_district_municipality_logo.png" alt="mopani-district-municipality-logo">
                    <img src="@/assets/telkom_logo.png" alt="telkom-logo">
                    <img src="@/assets/transnet_logo.png" alt="transnet-logo">
                    <img src="@/assets/vodacom_logo.png" alt="vodacom-logo">
                </div>
                <p>Our partnerships with our clients are based on regular communication and mutual trust. We go the extra mile to ensure that projects are completed timeously.</p>
            </div>


            <div class="content-heading-wrap">
                <h3 class="centered">Our Team</h3>
            </div>

            <div class="our-team-wrap">
                
                <div class="our-team-image-box">
                    <div class="our-team-text">
                        <p>A team of Engineers, Project Managers, a Quantity Surveyor and a Health and Safety officer, as well as qualified and experienced Operators in Trenchless Construction, execute projects nationally.</p>
                        <ol>
                            <li>Prompt and detailed quotations and competitive pricing.</li>
                            <li>Excellent turnaround time on projects.</li>
                            <li>Latest technology applied for the effective execution of projects.</li>
                            <li>Geared to handle minor as well as advanced projects.</li>
                        </ol>
                    </div>
                </div>
            </div>



            <div class="affiliations-wrap home-content-wrap">
                <h3 class="centered">Affiliations </h3>

                <p>Aardvark Construction keeps abreast with Trenchless technology and best practice by attending seminars and consulting with industry bodies. We are affiliated with the following organisations:</p>
                <div class="affiliations-logo-images-wrap">
                    <img src="@/assets/aardvark_construction_affiliations_cidb_logo.jpg" alt="aardvark_construction_affiliations_cidb_logo">
                    <img src="@/assets/aardvark_construction_affiliations_istt_logo.jpg" alt="aardvark_construction_affiliations_istt_logo">
                    <img src="@/assets/aardvark_construction_affiliations_sasst_logo.jpg" alt="aardvark_construction_affiliations_sasst_logo">
                    <img src="@/assets/aardvark_construction_affiliations_south_africa_central_suppliers_database_logo.jpg" alt="aardvark_construction_affiliations_south_africa_central_suppliers_database_logo">
                    <img src="@/assets/aardvark_construction_affiliations_south_africa_no_dig_logo.jpg" alt="aardvark_construction_affiliations_south_africa_no_dig_logo">
                </div>
            </div>


            <div class="products-and-services-wrap ">
                <!-- <div class="content-heading-wrap"> -->
                    <h3>Our Products & Services</h3>
                <!-- </div> -->

                <p>Aardvark Construction provides the following services in <router-link to="/trenchless_construction">Trenchless Construction:</router-link></p>
                <ul>
                    <li>Pipe Jacking</li>
                    <li>Pipe Cracking (or Pipe Bursting)</li>
                    <li>Sliplining of pipes</li>
                    <li>Horizontal Directional Drilling (including Rock Drilling)</li>
                    <li>CCTV Inspections</li>
                    <li>Butt Welding</li>
                </ul>
                <p class="space-bottom-p"><span class="bold"><router-link to="/pipe_jacking">Pipe Jacking</router-link></span> is a method of installing concrete pipelines without disrupting existing surface facilities in various jacking distances and ground conditions. Pipe Jacking is used for installation of concrete pipes or culverts from 900mm to 2400mm. </p>
                <p class="space-bottom-p bigger-space">Concrete pipes are jacked from a jacking pit to a receiver pit. The main jacks are installed in the jacking pit and are used to push the concrete pipes into the cavity excavated ahead of the progressing pipeline. The main jack capacity (two to four jacks with capacity of up to 300 ton) depends on the size of the pipe, length of the line and the type of ground. Aardvark Construction completed several Pipe Jacking projects since 2018 by crossing underneath roads, railways, streams, and buildings.</p>
                <p class="space-bottom-p"><span class="bold"><router-link to="/pipe_cracking">Pipe Cracking</router-link></span>(or Pipe Bursting) is a trenchless method of replacing existing pipelines such as sewer and water pipes, without the need for conventional construction. </p>
                <p class="space-bottom-p">Pipe Cracking refers to the technique of inserting a ‘cracking head’ into and through the existing pipe. In doing so, the old pipe fractures and sends the remaining materials and fragments into the surrounding soil area. At the same time, the new pipe is pulled into place.</p>
                <p class="space-bottom-p bigger-space">Sliplining happens when a new, smaller pipe is pulled throough a redundant existing pipe. Aardvark Construction commenced with Pipe Cracking and Sliplining in 2021 and completed more than 50 000 meters since then.</p>
                <p class="space-bottom-p bigger-space"><span class="bold"><router-link to="/horizontal_drilling">Horizontal Directional Drilling</router-link></span> replaces the installation of underground utilities using open trenching with equipment operated by an operator who tracks data and then bores the path accordingly. Horizontal Drilling is used in the process of installing hdpe sleeve pipes from 20mm to 800mm. It is the best alternative for a repair, maintenance, or installation project. Aardvark Construction has successfully completed more than 10 000 meters of HDD underneath roads, railways, streams and structures since 2010.</p>
                <p class="space-bottom-p bigger-space"><span class="bold"><router-link to="/cctv_inspections">CCTV Inspections</router-link></span> are done to locate and diagnose a specific blockage or problem in sewer, water and stormwater lines. A CCTV camera, feeding off a cable down a pipeline, sends footage to a screen where details can be viewed.</p>
                <p class="space-bottom-p"><span class="bold"><router-link to="/hdpe_butt_fusion_welding">Hdpe Butt Welding</router-link></span> forms an integral part in the installation of pipes during Horizontal Directional Drilling, Pipe Cracking and Slip Lining. It is the most efficient way to join hdpe pipes (from 63mm to 710mm) and fittings. Two pipe ends are heated and pressed together, creating a permanent joint that converts sections of pipe into a single, continuous line.</p>
                <p class="space-bottom-p">Please note that Horizontal Directional Drilling is used for the installation of hdpe, whereas Pipe Jacking is used for concrete pipes. Click on the following link for a full description and video clips of all our services: <router-link to="/trenchless_construction">Trenchless Construction Services</router-link>.</p>
            </div>


            <div class="trenchless-construction-benefits-wrap">
                <!-- <div class="content-heading-wrap"> -->
                    <h3>Benefits of Trenchless Construction</h3>
                <!-- </div> -->

                <ul>
                    <li>Trenchless Technology: no trench, no digging.</li>
                    <li>No disturbance of ground, structures, services, and traffic.</li>
                    <li>High speed efficiency that exceeds any conventional excavation or trenching.</li>
                    <li>No bedding or backfill required.</li>
                    <li>Less manpower required.</li>
                    <li>Safer than conventional construction.</li>
                    <li>More cost effective than conventional construction.</li>
                </ul>
            </div>


            



            



            <div class="offices-wrap">
                <h3>Offices</h3>
                <a href="https://maps.app.goo.gl/3NomszNbkNfutoLa8" target="_blank"><p><font-awesome-icon class="homepage-office-location-icon" :icon="['fa', 'location-dot']" size="lg" /> <span class="bold">Pretoria:</span> 5th Floor, Bloukrans Building, Lynnwood Bridge, 0081</p></a>
                <a href="https://maps.app.goo.gl/KwkQv6N3HFonBZsx6" target="_blank"><p><font-awesome-icon class="homepage-office-location-icon" :icon="['fa', 'location-dot']" size="lg" /> <span class="bold">Bloemfontein:</span> Unit 36, Transnet Road, Bloemfontein, 9301</p></a>
                <a href="https://maps.app.goo.gl/WPyfoiMSSZqHB6nN9" target="_blank"><p><font-awesome-icon class="homepage-office-location-icon" :icon="['fa', 'location-dot']" size="lg" /> <span class="bold">Cape Town:</span> 1st Floor, Block B, North Park, Black River Park, 2 Fir Street, Observatory, 7925 </p></a>
            </div>



            <div class="contact-details-wrap">
                <h3>Contact Details</h3>
                <p>Carl Kampherbeek</p>
                <a href="tel:+27834558321"><font-awesome-icon class="home-contact-icons" :icon="['fa', 'mobile-screen']" size="lg" /> +27 (0) 83 455 8321</a>
                <br>
                <a href="mailto:info@aardvarkconstruction.co.za"><font-awesome-icon class="home-contact-icons" :icon="['fa', 'envelope']" size="lg" />  info@aardvarkconstruction.co.za</a>
                <br>
                <a href="mailto:carl@aardvarkconstruction.co.za"><font-awesome-icon class="home-contact-icons" :icon="['fa', 'envelope']" size="lg" />  carl@aardvarkconstruction.co.za</a>
            </div>



            <div class="company-registration-wrap">
                <h3>Company Registration Details</h3>
                <p>Aardvark Construction</p>
                <p>Registration number: CK2002/012769/23</p>
                <p>VAT registration number: 4630294330</p>
                <p>Compensation fund number: 990001400341</p>
            </div>

            <br>
            <br>


        </div>

    </div>
</template>




<script>

import Slider from '../Slider/Slider.vue'

export default {

    components: {
        Slider
    },

    data() {
        return {
            
        }
    },

    
}
</script>




<style>

.content {
    z-index: 1;
    color: rgb(24, 24, 24);
    /* background: rgba(153, 230, 253, 0.8); */
    padding: 0 10%;
    font-size: 17px;
}





.content h3 {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 140px;
    margin-bottom: 20px;
    color: var(--AardvarkBlue);
    text-align: center;
    width: max-content;
    position: relative;
}

.content h3::after {
    position: absolute;
    left: 0;
    content: '';
    display: block;
    width: calc(100% + 40px);
    height: 2px;
    background: var(--AardvarkGreen);
    margin: 0 auto;
}

.content h3.centered::after {
    left: -20px;
}


.content-heading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content h3.low-top-margin {
    margin-top: 100px;
}




.home-content-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.intro-paragraph-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.intro-paragraph {
    max-width: 1200px;
}


.content ul, .content ol {
    text-align: left;
    padding-left: 15px;
    margin: 10px 0;
}


.content ol li {
    
}



.client-logo-wrap {
    
}

.client-logo-images-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0px 0;
    margin-bottom: 30px;
    padding: 0 20px;
}

.client-logo-images-wrap img {
    max-width: 150px;
    max-height: 80px;
    margin: 30px 25px;
    margin-bottom: 0;
}




.our-team-wrap {
    
}



.our-team-image-box {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: /* max-content */ 1fr;
    align-items: center;
}


.aardvark-our-team-image {
    margin-bottom: 20px;
    margin-right: 20px;
    
}



.our-team-text {
    height: max-content;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}   








.affiliations-logo-images-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0px 0;
    margin-bottom: 30px;
    padding: 0 40px;
}

.affiliations-logo-images-wrap img {
    max-width: 350px;
    max-height: 120px;
    margin: 50px 35px;
    margin-bottom: 0;
}











.offices-wrap p span.bold {
    text-transform: uppercase;
}


.offices-wrap a {
    text-decoration: none;
    color: rgb(24, 24, 24);
    /* font-weight: 700; */
}


.homepage-office-location-icon {
    margin-right: 10px;
    color: var(--AardvarkBlue);
}





.products-and-services-wrap ul {
    margin-bottom: 45px;
}


.products-and-services-wrap p span.bold {
    text-transform: uppercase;
}


.products-and-services-wrap a {
    text-decoration: none;
    color: var(--AardvarkBlue);
    /* font-weight: 700; */
}

.products-and-services-wrap a:hover {
    color: var(--AardvarkGreen);
}

.products-and-services-wrap .space-bottom-p {
    margin-bottom: 12px;
}

.products-and-services-wrap .space-bottom-p.bigger-space {
    margin-bottom: 45px;
}






.home-contact-icons {
    color :var(--AardvarkBlue);
}


.contact-details-wrap a {
    text-decoration: none;
    color: rgb(24, 24, 24);
}











.home-page-slider-wrap {
    /* height: 700px; */
    position: relative;
}







@media only screen and (max-width: 1400px) {
    
.content h3 {
    width: 100%;
    padding: 0 10px;
}

.content h3:after {
    width: 90%;
    max-width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.content h3.centered::after {
    left: 0;
}

.our-team-image-box {
    display: block;
}

.aardvark-our-team-image {
    margin-bottom: 20px;
    margin-right: 0;
    max-width: 90vw;
}

.content {
    padding: 0 5%;
}


.affiliations-logo-images-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0px 0;
    margin-bottom: 30px;
    padding: 0 40px;
}

.affiliations-logo-images-wrap img {
    max-width: 90%;
    margin: 50px 35px;
    margin-bottom: 0;
}



.offices-wrap a p {
    margin-bottom: 20px;
    text-align: center;
}

.contact-details-wrap {
    text-align: center;
}


}
</style>   