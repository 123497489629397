import router from "@/router";

// initial state
const state = () => ({
    selectedBlog: ''
})





// getters
const getters = {
    selectedBlog: (state) => state.selectedBlog
}





// actions
const actions = {
    setSelectedBlog({ commit }, blog) {
        commit('selectedBlog', blog);
        if(blog)
            router.push('/blog_article');
        else
            router.push('/blog_articles');
    }
}





// mutations
const mutations = {
    selectedBlog(state, blog) {
        state.selectedBlog = blog;
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

//remember to reg in index.js 